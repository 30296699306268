<script>
import {Offcanvas} from "bootstrap";
import {mapGetters} from "vuex";
import {debounce} from "@/utils/debounce";
import GameMixin from '@/mixins/GameMixin';
import {safeNumberConversion as __safety} from "@/utils/tools";

export default {
  mixins: [GameMixin],
  props: ['value', 'selectedPosition'],
  components: {
    RangeSlider: () => import(/* webpackChunkName: "range-slider" */ '@/components/RangeSlider.vue'),
  },
  data() {
    return {
      prevStack: 0,
      processing: false,
      offcanvasInstance: null,
      testTimer: null,
      testTime: new Date('2024-02-07 17:11:10'),
      seatType: 'pb',
    }
  },
  computed: {
    ...mapGetters(['sessionData']),
    ...mapGetters('game', ['gameInfo', 'roomInfo', 'myInfo', 'tableStack', 'isRelayGame', 'isTournamentGame']),
    smallBlind() {
      if (!this.roomInfo) {
        return 0;
      }

      return this.roomInfo.blindState.sb;
    },
    bigBlind() {
      if (!this.roomInfo) {
        return 0;
      }

      return this.roomInfo.blindState.bb;
    },
    buyin() {
      return this.gameInfo.buyin || 0;
    },
    minBuyin() {
      return this.buyin;
    },
    isSetBuyinLimit() {
      return this.gameInfo && 'maxBuyin' in this.gameInfo;
    },
    buyinLimit() {
      return this.isSetBuyinLimit ? this.gameInfo.maxBuyin : '*';
    },
    maxBuyin() {
      if (!this.isSetBuyinLimit || this.buyinLimit === '*') {
        return this.userCash;
      }

      return Math.min(this.userCash, __safety(this.gameInfo.maxBuyin));
    },
    userCash() {
      return __safety(this.sessionData.cash || 0);
    },
    isAdjustableBuyin() {
      return this.minBuyin < this.maxBuyin;
    },
    isSeated() {
      return this.myInfo.idx !== -1;
    },
    step() {
      const diff = this.maxBuyin - this.dynamicStack;

      if (diff > 0 && diff < this.basicStep) {
        return 1;
      }

      return this.basicStep;
    },
    basicStep() {
      const range = this.maxBuyin - this.minBuyin;
      const step = Math.max(10, Math.pow(10, Math.floor(Math.log10(range / 10))));
      return this.clamp(step, 100, 10000);
    },
    dynamicStack: {
      get() {
        return this.tableStack || this.maxBuyin;
      },
      set(value) {
        this.$store.commit('game/SET_TABLE_STACK', __safety(value));
      }
    },
    leftOffset() {
      return this.minBuyin % this.step;
    },
    rightOffset() {
      return this.maxBuyin % this.step;
    },
    internalStep() {
      return this.basicStep + this.rightOffset - this.leftOffset;
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.init();
      } else {
        this.removeBackdrop();
      }
    },
    maxBuyin: {
      immediate: true,
      handler() {
        if (this.isSetBuyinLimit && this.maxBuyin !== this.dynamicStack) {
          this.dynamicStack = this.maxBuyin;
        }
      }
    },
    dynamicStack: {
      immediate: true,
      handler() {
        this.prevStack = this.dynamicStack;
      }
    },
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.setModalListeners();
        this.showOffcanvas();
      });
    },
    onHidden($event) {
      this.removeBackdrop();

      this.$refs.offcanvasElement.removeEventListener('hidden.bs.offcanvas', this.onHidden, false);
      this.$emit('input', false);

      $event.stopPropagation();
    },
    setModalListeners() {
      this.$refs.offcanvasElement.addEventListener('hidden.bs.offcanvas', this.onHidden, false);
    },
    showOffcanvas() {
      this.offcanvasInstance = new Offcanvas(this.$refs.offcanvasElement, {
        backdrop: 'static'
      });

      this.offcanvasInstance.show();
    },
    hideOffcanvas() {
      this.offcanvasInstance = this.offcanvasInstance || new Offcanvas(this.$refs.offcanvasElement);
      this.offcanvasInstance.hide();
      this.removeBackdrop();
    },
    removeBackdrop() {
      this.$nextTick(() => {
        const backdrop = document.querySelector('.offcanvas-backdrop');

        if (!backdrop) {
          return;
        }

        backdrop.remove();
      });
    },
    decrease(value) {
      const diff = Math.abs(this.prevStack - value);

      // 최대값으로부터의 변화 예외 처리
      if (this.prevStack === this.maxBuyin) {
        if (diff <= this.internalStep) {
          const offset = (this.prevStack % this.step) || this.step;
          value = this.prevStack - offset;
        } else {
          const offset = (value % this.basicStep);
          value -= offset;
        }
      } else {
        const offset = (value % this.basicStep);
        value -= offset;
      }

      this.dynamicStack = this.clamp(value, this.minBuyin, this.maxBuyin);
    },
    increase(value) {
      const diff = Math.abs(this.maxBuyin - this.prevStack);

      const remainder = (this.maxBuyin % this.basicStep) || this.basicStep;

      if (diff === remainder) {
        value = this.maxBuyin;
      } else {
        const offset = (value % this.basicStep);
        value -= offset;
      }

      this.dynamicStack = this.clamp(value, this.minBuyin, this.maxBuyin);
    },
    updateBuyinCashValue(value) {
      if (!this.isAdjustableBuyin) {
        return;
      }

      const direction = this.prevStack < value ? 1 : -1;

      switch (direction) {
        case -1:
          this.decrease(value);
          break;
        case 1:
          this.increase(value);
          break;
      }
    },
    handleSliderInput(value) {
      this.updateBuyinCashValue(value);
    },
    handleBuyinOnWheel($event) {
      const direction = $event.deltaY < 0 ? 1 : -1;
      let value = this.dynamicStack + (direction * this.step);
      this.updateBuyinCashValue(value);
    },
    clamp(value, min, max) {
      return Math.max(min, Math.min(value, max));
    },
    canJoinGame() {
      if (this.isSeated) {
        window.location.reload();
        return false;
      }

      if (this.processing) {
        // alert(`요청을 처리 중입니다. 잠시만 기다려 주세요.`);
        this.$swal.fire({
          title: `요청을 처리 중입니다. 잠시만 기다려 주세요.`,
          icon: "warning",
        });
        return false;
      }

      const isValidCash = this.dynamicStack >= this.minBuyin && this.dynamicStack <= this.maxBuyin;

      if (!isValidCash) {
        // alert(`게임 포인트를 확인해 주세요.`);
        this.$swal.fire({
          title: `게임 포인트를 확인해 주세요.`,
          icon: "warning",
        });
        return false;
      }

      return isValidCash && !this.isSeated && !this.processing;
    },
    // 게임 참여
    buyinAction: debounce(async function () {
      if (!this.canJoinGame()) {
        this.close();
        return;
      }

      this.processing = true;

      try {
        await this.seat({
          buyin: this.buyin,
          userCash: this.userCash,
          userTableStack: this.dynamicStack,
          position: this.selectedPosition - 1,
          seatType: this.seatType,
        });

        await this.$store.dispatch('auth_check');
        this.dynamicStack = 0;
      } finally {
        this.close();
      }
    }, 500, {leading: true}),
    close() {
      this.hideOffcanvas();
      this.processing = false;
    },
    setSeatType(seatType){
      this.seatType = seatType
    },
    isActiveSeatType(seatType) {
      return this.seatType === seatType;
    }
  },
}
</script>

<template>
  <!-- 바이인 레이어팝업 -->
  <transition name="slide-popup" mode="in-out">

    <div
      v-if="value"
      ref="offcanvasElement"
      id="layerpopup_buyin"
      class="layerpopup_buyin offcanvas offcanvas-bottom"
      :class="{ show: value }"
      tabindex="-1"
    >
      <div class="holding_coin">보유코인
        <div class="user_point">
          <span class="ico_coin"></span>
          <span class="num">{{ userCash | formatCash }}</span>
        </div>
      </div>
      <div class="wrap_buyin">
        <div class="blind_point" v-if="isRelayGame">
          <span>{{ smallBlind | formatCash }}</span>
          <span> / </span>
          <span>{{ bigBlind | formatCash }}</span>
        </div>
        <div class="buyin_coin" v-if="isTournamentGame" :class="{tournament: isTournamentGame}">
          <span v-if="minBuyin === 0">프리롤</span>
          <span v-else>{{ minBuyin | formatCash }}</span>
        </div>
        <div class="buyin_coin" v-if="isRelayGame">{{ dynamicStack | formatCash }}</div>
        <div class="coin_range" v-if="isRelayGame">
          <div class="pe-3">{{ minBuyin | formatCash }}</div>
          <div>
            <RangeSlider
              id="customRange1"
              class="form-range"
              :step="basicStep"
              :min="minBuyin"
              :max="maxBuyin"
              v-model="dynamicStack"
            />
          </div>
          <div class="ps-3">{{ maxBuyin | formatCash }}</div>
        </div>
        <div class="btn_area">
          <button type="button" class="btn_d btn_gray me-3" aria-label="Close" @click.prevent.stop="close">취소
          </button>
          <button
            ref="buyin"
            type="button"
            class="btn_d btn_blue"
            data-bs-toggle="offcanvas"
            data-bs-target="#layerpopup_buyin"
            aria-controls="offcanvasBottom"
            @click.prevent.stop="buyinAction"
          >
            확인
          </button>
        </div>
        <div class="area_wait" v-if="isRelayGame">
          <button type="button" class="btn_wait me-1" @click.prevent.stop="setSeatType('pb')" :class="{active: isActiveSeatType('pb')}">바로참여</button>
          <button type="button" class="btn_wait" @click.prevent.stop="setSeatType('bb')" :class="{active: isActiveSeatType('bb')}">빅블라인드 참여대기</button>
        </div>
      </div>
    </div>
  </transition>
  <!-- //바이인 레이어팝업 -->
</template>

<style scoped>
.slide-popup-enter-active, .slide-popup-leave-active {
  transition-delay: 0.3s;
  transition: 0.2s ease;
}

.slide-popup-enter, .slide-popup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

input[type="range"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  opacity: 0.9;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

input[type="range"]:hover {
  opacity: 1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -1rem;
  width: 2.4rem;
  height: 2.4rem;
  background: #378CE7;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .5);
  border: 0.12rem solid #fff;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  margin-top: -1rem;
  width: 2.4rem;
  height: 2.4rem;
  background: #378CE7;
  border: 0.12rem solid #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .5);
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -1rem;
  width: 2.4rem;
  height: 2.4rem;
  background: #378CE7;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .5);
  border: 0.12rem solid #fff;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:active {
  background-color: transparent;
  border: 2px dashed #c9cc55;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, .8);
  cursor: grabbing;
}

input[type="range"]::-moz-range-thumb:active {
  -moz-appearance: none;
  background-color: transparent;
  border: 2px dashed #c9cc55;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .8);
  cursor: grabbing;
}

input[type="range"]::-ms-thumb:active {
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px dashed #c9cc55;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .8);
  cursor: grabbing;
}

.user_point .num {
  display: inline-block;
  width: calc(100% - 3rem);
  text-align: right;
}

.buyin_coin {
  width: 20rem;
  max-width: 75%;
}

</style>
