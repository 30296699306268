import * as gameSock from "../gameSock";
import {mapGetters, mapMutations} from "vuex";

export default {
    computed: {
        ...mapGetters('game', ['isCallReserved', 'isFoldReserved']),
    },
    methods: {
        ...mapMutations('game', {
            __reserveCall: 'SET_RESERVE_CALL',
            __reserveFold: 'SET_RESERVE_FOLD',
        }),
        async seat(payload) {
            try {
                return await gameSock.poker_seat(
                    this.$socket,
                    {
                        roomCode: Number(this.$route.query.roomCode),
                        ...payload
                    }
                );
            } catch (body) {
                const msg = this.errorProc(`seat`, body);
                // alert(msg);
                this.$swal.fire({
                    title: msg,
                    icon: "error",
                });
            }
        },
        async bet(roomId, playerId, betAmount) {
            try {
                return await gameSock.poker_action_raise(this.$socket, roomId, playerId, betAmount)
            } catch (errorBody) {
                const msg = this.errorProc(`action`, errorBody);
                //alert(msg)
                this.$swal.fire({
                    title: msg,
                    icon: "error",
                });
            }
        },
        async call(roomId, playerId) {
            try {
                this.__reserveCall(false);
                await gameSock.poker_action_call(this.$socket, roomId, playerId)
            } catch (errorBody) {
                const msg = this.errorProc(`action`, errorBody);
                // alert(msg)
                this.$swal.fire({
                    title: msg,
                    icon: "error",
                });
            }
        },
        async fold(roomId, playerId) {
            try {
                this.__reserveFold(false);

                await gameSock.poker_action_fold(this.$socket, roomId, playerId);
            } catch (errorBody) {
                const msg = this.errorProc(`action`, errorBody);
                // alert(msg)
                this.$swal.fire({
                    title: msg,
                    icon: "error",
                });
            }
        },
    }
};
